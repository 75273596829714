import getters from "@/store/modules/test/getters";
import mutations from "@/store/modules/test/mutations";
import actions from "@/store/modules/test/actions";
export default {
  state: {
    questions: [],
    answers: [],
    actualQuestionsId: 0,
    results: [],
    childResults: [],
    answersPassed: 0,
    answersList: {},
  },
  getters,
  mutations,
  actions,
};
