export default {
  setAvailableTasks(state, list) {
    state.availableTasks = list;
  },
  setLatestTasks(state, list) {
    state.latestTasks = list;
  },
  setLatestResults(state, list) {
    state.latestResults = list;
  },
  setCnt(state, cnt) {
    state.cnt = cnt;
  },
  setActiveTask(state, data) {
    state.activeTask = data;
  },
};
