import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import notices from "@/store/modules/notices/index";
import user from "@/store/modules/user/index";
import test from "@/store/modules/test/index";
import org from "@/store/modules/org/index";
import task from "@/store/modules/task/index";
const store = new Vuex.Store({
  modules: {
    notices,
    user,
    test,
    org,
    task,
  },
});
export default store;
