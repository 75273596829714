export default {
  getAvailableTasks: (state) => {
    return state.availableTasks;
  },
  getLatestTasks: (state) => {
    let data = [];
    for (let i in state.latestResults) {
      let elem = state.latestResults[i];
      let taskId = state.latestResults[i].task_id;
      let result = state.latestTasks.find((el) => el.id == taskId);
      elem.id = taskId;
      elem.level = result.level;
      elem.competence = result.competence;
      elem.task = result.task;
      data.push(elem);
    }
    return data;
  },
  getCnt: (state) => {
    return state.cnt;
  },
  getActiveTask: (state) => {
    return state.activeTask;
  },
};
