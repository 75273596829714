import getters from "@/store/modules/user/getters";
import mutations from "@/store/modules/user/mutations";
import actions from "@/store/modules/user/actions";
export default {
  state: {
    token: "",
    id: 0,
    user: {},
    users: [],
    usernameAllowed: true,
    children: [],
  },
  getters,
  mutations,
  actions,
};
