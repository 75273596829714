import {
  MUTATION_NOTICES_PUSH,
  MUTATION_TASK_SETLATESTRESULTS,
  MUTATION_TASK_SETACTIVETASK,
  MUTATION_TASK_SETCNT,
  MUTATION_TASK_SETLATESTTASKS,
  MUTATION_TASK_SETAVAILABLETASKS,
} from "@/store/mutations.type";
import {
  requestTaskSettask,
  requestTaskEndtask,
  requestTaskGetlatesttasks,
  requestTaskGetavailabletasks,
  requestTaskGettask,
} from "@/api/taskRequests";
export default {
  async setTask({ commit }, id) {
    try {
      await requestTaskSettask(id);
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Задача взята в работу" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось взять задачу в работу" }],
      });
      return false;
    }
  },
  async endTask({ commit }, data) {
    try {
      await requestTaskEndtask(data);
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Задача выполнена" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось завершить задачу" }],
      });
      return false;
    }
  },
  async getLatesttasks({ commit }) {
    try {
      const response = await requestTaskGetlatesttasks();
      commit(MUTATION_TASK_SETLATESTTASKS, response.data.tasks);
      commit(MUTATION_TASK_SETLATESTRESULTS, response.data.results);
      commit(MUTATION_TASK_SETCNT, response.data.cnt);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [
          { message: "Не удалось получить последние выполненные задачи" },
        ],
      });
      return false;
    }
  },
  async getAvailabletasks({ commit }) {
    try {
      const response = await requestTaskGetavailabletasks();
      commit(MUTATION_TASK_SETAVAILABLETASKS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить доступные задачи" }],
      });
      return false;
    }
  },
  async getTask({ commit }, id) {
    try {
      const response = await requestTaskGettask(id);
      if (response.data) commit(MUTATION_TASK_SETACTIVETASK, response.data);
      else {
        commit(MUTATION_TASK_SETACTIVETASK, {
          id: 0,
          task: "",
          level: 0,
          competence: "",
        });
      }
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить активную задачу" }],
      });
      return false;
    }
  },
};
