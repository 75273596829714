import {
  MUTATION_ORG_SETPOSITIONS,
  MUTATION_ORG_SETEDUCATIONS,
  MUTATION_ORG_SETSKILLS,
  MUTATION_NOTICES_PUSH,
  MUTATION_ORG_SETSUITPOSITIONS,
  MUTATION_ORG_SETSUITUSERS,
} from "@/store/mutations.type";
import {
  requestOrgPositionslist,
  requestOrgSearchpositions,
  requestOrgSearchusers,
  requestOrgEdulist,
  requestOrgSkillslist,
  requestOrgSaveposition,
  requestOrgDelposition,
} from "@/api/orgRequests";
export default {
  async orgSaveposition({ commit }, data) {
    try {
      let sendData = {};
      sendData.id = data.id;
      sendData.position = data.name;
      sendData.parent_id = data.parent_id.id;
      sendData.low = data.low.join(",");
      sendData.needle = data.needle.join(",");
      await requestOrgSaveposition(sendData);
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Должность " + sendData.position + " сохранена" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось сохранить должность" }],
      });
      return false;
    }
  },
  async orgDelposition({ commit }, id) {
    try {
      const r = await requestOrgDelposition(id);
      console.log(r.data);
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Должность удалена" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить удалить должность" }],
      });
      return false;
    }
  },
  async orgLoadpositions({ commit }) {
    try {
      const response = await requestOrgPositionslist();
      commit(MUTATION_ORG_SETPOSITIONS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить список должностей" }],
      });
      return false;
    }
  },
  async orgLoadeducations({ commit }) {
    try {
      const response = await requestOrgEdulist();
      commit(MUTATION_ORG_SETEDUCATIONS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [
          { message: "Не удалось получить список уровней образования" },
        ],
      });
      return false;
    }
  },
  async orgLoadskills({ commit }) {
    try {
      const response = await requestOrgSkillslist();
      commit(MUTATION_ORG_SETSKILLS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить список навыков" }],
      });
      return false;
    }
  },
  async orgLoadsuitpositions({ commit }, id) {
    try {
      const response = await requestOrgSearchpositions(id);
      commit(MUTATION_ORG_SETSUITPOSITIONS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить список должностей" }],
      });
      return false;
    }
  },
  async orgLoadsuitusers({ commit }, id) {
    try {
      const response = await requestOrgSearchusers(id);
      commit(MUTATION_ORG_SETSUITUSERS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить список пользователей" }],
      });
      return false;
    }
  },
};
