export default {
  setResults(state, data) {
    state.results = data;
  },
  setChildresults(state, data) {
    state.childResults = data;
  },
  setAnswerspassed(state, data) {
    state.answersPassed = data;
  },
  setTestuserdata(state, data) {
    state.answers = data.answers;
    state.questions = data.questions;
    if (data.answersList.length == 0) {
      state.answersList = new Object();
    } else state.answersList = data.answersList;
  },
  setAnslistforq(state, data) {
    state.answersList[data.qid] = data.ansList;
  },
};
