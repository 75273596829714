import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_TEST_GETQUESTION,
  APIPOINT_TEST_GETQUESTIONS,
  APIPOINT_TEST_SAVEANSWER,
  APIPOINT_TEST_GETACTUALQUESTION,
  APIPOINT_TEST_ENDTEST,
  APIPOINT_TEST_GETRESULTS,
  APIPOINT_TEST_DELETERESULT,
  APIPOINT_TEST_ANSWERSPASSED,
  APIPOINT_TEST_USERDATA,
  APIPOINT_TEST_GETCHILDRESULTS,
} from "@/api/points";

export const requestTestGetquestion = function (id) {
  return Axios.post(APIPOINT_TEST_GETQUESTION, { id });
};
export const requestTestGetquestions = function () {
  return Axios.post(APIPOINT_TEST_GETQUESTIONS);
};
export const requestTestSaveanswer = function (json) {
  return Axios.post(APIPOINT_TEST_SAVEANSWER, { json });
};
export const requestTestGetactualquestion = function () {
  return Axios.post(APIPOINT_TEST_GETACTUALQUESTION);
};
export const requestTestEndtest = function () {
  return Axios.post(APIPOINT_TEST_ENDTEST);
};
export const requestTestGetresults = function () {
  return Axios.post(APIPOINT_TEST_GETRESULTS);
};
export const requestTestGetchildresults = function (id) {
  return Axios.post(APIPOINT_TEST_GETCHILDRESULTS, { id });
};
export const requestTestDelresults = function (id) {
  return Axios.post(APIPOINT_TEST_DELETERESULT, { id });
};
export const requestTestAnswerspassed = function (id) {
  return Axios.post(APIPOINT_TEST_ANSWERSPASSED, { id });
};
export const requestTestUserdata = function () {
  return Axios.post(APIPOINT_TEST_USERDATA);
};
