export default {
  orgPositionslist: (state) => {
    return state.positions;
  },
  orgPositionslistWithoutRoot: (state) => {
    return state.positions.filter(function (p) {
      return p.parent_id != 0;
    });
  },
  orgEditId: (state) => {
    return state.editId;
  },
  orgPositionsByParent: (state) => (id) => {
    let data = [];
    for (let i in state.positions) {
      if (state.positions[i].parent_id == id) data.push(state.positions[i]);
    }
    return data;
  },
  orgPositionById: (state) => (id) => {
    return state.positions.find((el) => el.id == id);
  },
  orgEdulist: (state) => {
    return state.educations;
  },
  orgEdubyid: (state) => (id) => {
    return state.educations.find((el) => el.id == id);
  },
  orgSkillslist: (state) => {
    return state.skills;
  },
  orgSkillById: (state) => (id) => {
    return state.skills.find((el) => el.id == id);
  },
  getSuitpositions: (state) => {
    return state.suitPositions;
  },
  getSuitusers: (state) => {
    return state.suitUsers;
  },
};
