import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_USER_REG,
  APIPOINT_USER_AUTH,
  APIPOINT_USER_CHECKTOKEN,
  APIPOINT_USER_CHECKUSERNAME,
  APIPOINT_USER_INFO,
  APIPOINT_USER_SAVEPASS,
  APIPOINT_USER_SAVEPROFILE,
  APIPOINT_USER_LOGOUT,
  APIPOINT_USER_GETCHILDREN,
} from "@/api/points";

export const requestUserReg = function (data) {
  return Axios.post(APIPOINT_USER_REG, data);
};
export const requestUserAuth = function (user, pass) {
  return Axios.post(APIPOINT_USER_AUTH, { user, pass });
};
export const requestUserChecktoken = function (token) {
  return Axios.post(APIPOINT_USER_CHECKTOKEN, { token });
};
export const requestUserCheckusername = function (name) {
  return Axios.post(APIPOINT_USER_CHECKUSERNAME, { name });
};
export const requestUserInfo = function (id) {
  return Axios.post(APIPOINT_USER_INFO, { id });
};
export const requestUserSavepass = function (pass) {
  return Axios.post(APIPOINT_USER_SAVEPASS, { pass });
};
export const requestUserSaveprofile = function (data) {
  return Axios.post(APIPOINT_USER_SAVEPROFILE, data);
};
export const requestUserLogout = function () {
  return Axios.post(APIPOINT_USER_LOGOUT);
};
export const requestUserGetchildren = function () {
  return Axios.post(APIPOINT_USER_GETCHILDREN);
};
