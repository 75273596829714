export default {
  userSettoken(state, payload) {
    state.token = payload;
  },
  userSet(state, info) {
    state.user = info;
    state.id = info.id;
  },
  userSetlist(state, list) {
    state.users = list;
  },
  setUsernameAllowed(state, val) {
    state.usernameAllowed = val;
  },
  setChildren(state, list) {
    state.children = list;
  },
};
