// пользователь
export const APIPOINT_USER_REG = "user_reg";
export const APIPOINT_USER_AUTH = "user_auth";
export const APIPOINT_USER_CHECKTOKEN = "user_checkToken";
export const APIPOINT_USER_CHECKUSERNAME = "user_checkUsername";
export const APIPOINT_USER_INFO = "user_info";
export const APIPOINT_USER_SAVEPASS = "user_savePass";
export const APIPOINT_USER_SAVEPROFILE = "user_saveProfile";
export const APIPOINT_USER_LOGOUT = "user_logout";
export const APIPOINT_USER_GETCHILDREN = "user_getChildren";

// тестирование
export const APIPOINT_TEST_GETQUESTION = "test_getQuestion";
export const APIPOINT_TEST_GETQUESTIONS = "test_getQuestions";
export const APIPOINT_TEST_SAVEANSWER = "test_saveAnswer";
export const APIPOINT_TEST_GETACTUALQUESTION = "test_getActualQuestion";
export const APIPOINT_TEST_ENDTEST = "test_endTest";
export const APIPOINT_TEST_GETRESULTS = "test_getResults";
export const APIPOINT_TEST_GETCHILDRESULTS = "test_getChildResults";
export const APIPOINT_TEST_DELETERESULT = "test_deleteResults";
export const APIPOINT_TEST_ANSWERSPASSED = "test_answersPassed";
export const APIPOINT_TEST_USERDATA = "test_userData";

// орг структура
export const APIPOINT_ORG_SAVEPOSITION = "org_savePosition";
export const APIPOINT_ORG_DELPOSITION = "org_delPosition";
export const APIPOINT_ORG_POSITIONSLIST = "org_positionsList";
export const APIPOINT_ORG_SEARCHPOSITIONS = "org_searchPositions";
export const APIPOINT_ORG_SEARCHUSERS = "org_searchUsers";
export const APIPOINT_ORG_EDULIST = "org_eduList";
export const APIPOINT_ORG_SKILLSLIST = "org_skillsList";

// задачи
export const APIPOINT_TASK_SETTASK = "task_setTask";
export const APIPOINT_TASK_GETTASK = "task_getTask";
export const APIPOINT_TASK_ENDTASK = "task_endTask";
export const APIPOINT_TASK_GETLATESTTASKS = "task_getLatestTasks";
export const APIPOINT_TASK_GETAVAILABLETASKS = "task_getAvailableTasks";
