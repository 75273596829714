import getters from "@/store/modules/org/getters";
import mutations from "@/store/modules/org/mutations";
import actions from "@/store/modules/org/actions";
export default {
  state: {
    positions: [],
    educations: [],
    suitPositions: [],
    suitUsers: { users: [], deltas: [] },
    skills: [],
    editId: 0,
  },
  getters,
  mutations,
  actions,
};
