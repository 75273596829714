export const MUTATION_USER_SAVEPASS = "userSavepass";
export const MUTATION_USER_SAVEPROFILE = "userSaveprofile";
export const MUTATION_USER_SETTOKEN = "userSettoken";
export const MUTATION_USER_SET = "userSet";
export const MUTATION_USER_SETLIST = "userSetlist";
export const MUTATION_USER_SETUSERNAMEALLOWED = "setUsernameAllowed";
export const MUTATION_USER_SETCHILDREN = "setChildren";
export const MUTATION_TEST_SAVEANSWER = "testSaveanswer";
export const MUTATION_TEST_SETRESULTS = "setResults";
export const MUTATION_TEST_SETCHILDRESULTS = "setChildresults";
export const MUTATION_TEST_SETANSWERSPASSED = "setAnswerspassed";
export const MUTATION_TEST_SETTESTUSERDATA = "setTestuserdata";
export const MUTATION_TEST_SETANSWER = "setAnswer";
export const MUTATION_TEST_SETANSLISTFORQ = "setAnslistforq";
export const MUTATION_ORG_SAVEPOSITION = "orgSaveposition";
export const MUTATION_ORG_DELPOSITION = "orgDelposition";
export const MUTATION_ORG_SETPOSITIONS = "orgSetpositions";
export const MUTATION_ORG_SETEDUCATIONS = "orgSeteducations";
export const MUTATION_ORG_SETSKILLS = "orgSetskills";
export const MUTATION_ORG_SETSUITPOSITIONS = "orgSetsuitpositions";
export const MUTATION_ORG_SETSUITUSERS = "orgSetsuitusers";
export const MUTATION_ORG_SETEDITID = "orgSeteditid";
export const MUTATION_TASK_SETACTIVETASK = "setActiveTask";
export const MUTATION_TASK_SETCNT = "setCnt";
export const MUTATION_TASK_SETLATESTRESULTS = "setLatestResults";
export const MUTATION_TASK_SETLATESTTASKS = "setLatestTasks";
export const MUTATION_TASK_SETAVAILABLETASKS = "setAvailableTasks";
export const MUTATION_NOTICES_PUSH = "pushNotices";
