import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_TASK_SETTASK,
  APIPOINT_TASK_ENDTASK,
  APIPOINT_TASK_GETLATESTTASKS,
  APIPOINT_TASK_GETTASK,
  APIPOINT_TASK_GETAVAILABLETASKS,
} from "@/api/points";

export const requestTaskSettask = function (id) {
  return Axios.post(APIPOINT_TASK_SETTASK, { id });
};
export const requestTaskEndtask = function (data) {
  return Axios.post(APIPOINT_TASK_ENDTASK, data);
};
export const requestTaskGetlatesttasks = function () {
  return Axios.post(APIPOINT_TASK_GETLATESTTASKS);
};
export const requestTaskGetavailabletasks = function () {
  return Axios.post(APIPOINT_TASK_GETAVAILABLETASKS);
};
export const requestTaskGettask = function (id) {
  return Axios.post(APIPOINT_TASK_GETTASK, { id });
};
