import Vue from "vue";
import VueRouter from "vue-router";
import {
  ROUTE_INDEX,
  ROUTE_AUTH,
  ROUTE_REG,
  ROUTE_TEST,
  ROUTE_RESULTS,
  ROUTE_EDU,
  ROUTE_ORG,
  ROUTE_PROFILE,
} from "@/router/routes.type";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: ROUTE_INDEX,
    redirect: { name: ROUTE_AUTH },
  },
  {
    path: "/auth",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "signin/",
        name: ROUTE_AUTH,
        component: () => import("@/views/AuthView.vue"),
      },
      {
        path: "signup/",
        name: ROUTE_REG,
        component: () => import("@/views/RegView.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: ROUTE_TEST,
    component: () => import("@/views/TestView.vue"),
  },
  {
    path: "/results",
    name: ROUTE_RESULTS,
    component: () => import("@/views/ResultsView.vue"),
  },
  {
    path: "/edu",
    name: ROUTE_EDU,
    component: () => import("@/views/EduView.vue"),
  },
  {
    path: "/org",
    name: ROUTE_ORG,
    component: () => import("@/views/OrgView.vue"),
  },
  {
    path: "/profile",
    name: ROUTE_PROFILE,
    component: () => import("@/views/ProfileView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
