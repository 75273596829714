import {
  MUTATION_USER_SETTOKEN,
  MUTATION_USER_SETUSERNAMEALLOWED,
  MUTATION_NOTICES_PUSH,
  MUTATION_USER_SET,
  MUTATION_USER_SETLIST,
  MUTATION_USER_SETCHILDREN,
} from "@/store/mutations.type";
import {
  requestUserAuth,
  requestUserChecktoken,
  requestUserReg,
  requestUserCheckusername,
  requestUserInfo,
  requestUserLogout,
  requestUserSavepass,
  requestUserSaveprofile,
  requestUserGetchildren,
} from "@/api/userRequests";
export default {
  async userAuth({ commit }, requestData) {
    try {
      const response = await requestUserAuth(
        requestData.username,
        requestData.pass
      );
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "Token=" + response.data + ";" + expires + ";path=/";
      commit(MUTATION_USER_SETTOKEN, response.data);
      if (response.data) {
        commit(MUTATION_NOTICES_PUSH, {
          notices: [{ message: "Аутентификация прошла успешно" }],
        });

        return true;
      } else {
        commit(MUTATION_NOTICES_PUSH, {
          notices: [{ message: "Учётная запись не найдена" }],
        });

        return false;
      }
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Произошла ошибка" }],
      });
      return false;
    }
  },
  async userReg({ commit }, requestData) {
    try {
      const response = await requestUserReg(requestData);
      let result = response.data;
      if (result)
        commit(MUTATION_NOTICES_PUSH, {
          notices: [
            { message: "Вы успешно зарегистрировались и можете войти" },
          ],
        });
      else
        commit(MUTATION_NOTICES_PUSH, {
          notices: [
            { message: "При регистрации произошла неизвестная ошибка33" },
          ],
        });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "При регистрации произошла неизвестная ошибка" }],
      });
      return false;
    }
  },
  async userChecktoken({ commit }) {
    try {
      const c = document.cookie.split("; ");
      let Token = "";
      for (const i in c) {
        let pair = c[i].split("=");
        if (pair[0] == "Token") Token = pair[1];
      }
      commit(MUTATION_USER_SETTOKEN, Token);
      const response = await requestUserChecktoken(Token);
      if (response.data) {
        commit(MUTATION_USER_SET, response.data);
        return response.data.id;
      } else {
        return false;
      }
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Требуется авторизация" }],
      });
      return false;
    }
  },
  async userCheckusername({ commit }, name) {
    try {
      const response = await requestUserCheckusername(name);
      commit(MUTATION_USER_SETUSERNAMEALLOWED, response.data);
      return response.data;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [
          { message: "Ошибка проверки существования имени пользователя" },
        ],
      });
      return false;
    }
  },
  async userInfo({ commit }, id) {
    try {
      const response = await requestUserInfo(id);
      commit(MUTATION_USER_SETLIST, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Ошибка получения информации о пользователе" }],
      });
      return false;
    }
  },
  async getChildren({ commit }) {
    try {
      const response = await requestUserGetchildren();
      commit(MUTATION_USER_SETCHILDREN, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Ошибка получения подчинённых" }],
      });
      return false;
    }
  },
  async userLogout({ commit }) {
    try {
      await requestUserLogout();
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "До свидания!" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Ошибка выхода" }],
      });
      return false;
    }
  },
  async userSavepass({ commit }, pass) {
    try {
      await requestUserSavepass(pass);
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Пароль изменён" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Ошибка изменения пароля" }],
      });
      return false;
    }
  },
  async userSaveprofile({ commit }, data) {
    try {
      await requestUserSaveprofile(data);
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Профиль изменён" }],
      });
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Ошибка изменения профиля" }],
      });
      return false;
    }
  },
};
