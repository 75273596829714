export default {
  token: (state) => {
    return state.token;
  },
  userById: (state) => (id) => {
    let user = state.users.find((el) => el.id == id);
    if (!user) {
      user = {
        active_task: 0,
        birthday: 0,
        education: 0,
        experience: 0,
        fitting_method: "",
        gender: "",
        id: 0,
        name: "",
        password: "",
        patronomic: "",
        position: 0,
        profile: "",
        surname: "",
        task_started_at: 0,
        token: "",
        tokentime: 0,
        username: "",
      };
    }
    return user;
  },
  user: (state) => {
    return state.user;
  },
  getCurUserInfo: (state) => {
    return state.curUser;
  },
  getId: (state) => {
    return state.id;
  },
  usernameAllowed: (state) => {
    return state.usernameAllowed;
  },
  userGetChildren: (state) => {
    return state.children;
  },
};
