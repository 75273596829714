import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_ORG_SAVEPOSITION,
  APIPOINT_ORG_DELPOSITION,
  APIPOINT_ORG_POSITIONSLIST,
  APIPOINT_ORG_SKILLSLIST,
  APIPOINT_ORG_SEARCHPOSITIONS,
  APIPOINT_ORG_SEARCHUSERS,
  APIPOINT_ORG_EDULIST,
} from "@/api/points";

export const requestOrgSaveposition = function (data) {
  return Axios.post(APIPOINT_ORG_SAVEPOSITION, data);
};
export const requestOrgDelposition = function (id) {
  return Axios.post(APIPOINT_ORG_DELPOSITION, { id });
};
export const requestOrgPositionslist = function () {
  return Axios.post(APIPOINT_ORG_POSITIONSLIST);
};
export const requestOrgSkillslist = function () {
  return Axios.post(APIPOINT_ORG_SKILLSLIST);
};
export const requestOrgSearchpositions = function (id) {
  return Axios.post(APIPOINT_ORG_SEARCHPOSITIONS, { id });
};
export const requestOrgSearchusers = function (id) {
  return Axios.post(APIPOINT_ORG_SEARCHUSERS, { id });
};
export const requestOrgEdulist = function () {
  return Axios.post(APIPOINT_ORG_EDULIST);
};
