import {
  MUTATION_TEST_SETRESULTS,
  MUTATION_NOTICES_PUSH,
  MUTATION_TEST_SETANSWERSPASSED,
  MUTATION_TEST_SETTESTUSERDATA,
  MUTATION_TEST_SETCHILDRESULTS,
} from "@/store/mutations.type";
import {
  requestTestGetresults,
  requestTestAnswerspassed,
  requestTestUserdata,
  requestTestSaveanswer,
  requestTestEndtest,
  requestTestDelresults,
  requestTestGetchildresults,
} from "@/api/testRequests";
export default {
  async testEndtest({ commit }) {
    try {
      await requestTestEndtest();
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось завершить тестирование" }],
      });
      return false;
    }
  },
  async testSaveanswer({ commit }, json) {
    try {
      await requestTestSaveanswer(json);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось сохранить данные на сервере" }],
      });
      return false;
    }
  },
  async testLoadresults({ commit }) {
    try {
      const response = await requestTestGetresults();
      commit(MUTATION_TEST_SETRESULTS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить результаты тестирований" }],
      });
      return false;
    }
  },
  async testLoadchildresults({ commit }, id) {
    try {
      const response = await requestTestGetchildresults(id);
      commit(MUTATION_TEST_SETCHILDRESULTS, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить результаты тестирований" }],
      });
      return false;
    }
  },
  async testDelresult({ commit }, id) {
    try {
      await requestTestDelresults(id);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось удалить результат" }],
      });
      return false;
    }
  },

  async testAnswerspassed({ commit }) {
    try {
      const response = await requestTestAnswerspassed();
      commit(MUTATION_TEST_SETANSWERSPASSED, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [
          { message: "Не удалось получить количество пройденных вопросов" },
        ],
      });
      return false;
    }
  },
  async testUserdata({ commit }) {
    try {
      const response = await requestTestUserdata();
      commit(MUTATION_TEST_SETTESTUSERDATA, response.data);
      return true;
    } catch (error) {
      commit(MUTATION_NOTICES_PUSH, {
        notices: [{ message: "Не удалось получить данные для тестирования" }],
      });
      return false;
    }
  },
};
