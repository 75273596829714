const TIMEOUT = 4000;
let noticesTimer = null;
export default {
  pushNotices(state, payload) {
    state.notices = state.notices.concat(payload.notices);
    if (noticesTimer) {
      clearTimeout(noticesTimer);
    }
    noticesTimer = setTimeout(() => (state.notices = []), TIMEOUT);
  },
};
