import axios from "axios";
import Store from "@/store";
//import { PUSH_NOTICES } from "@/store/mutations.type";

const Axios = axios.create({ baseURL: process.env.VUE_APP_API_ACCESSPOINT });

/*
const buildErrorMessages = function (data) {
  const notices = [];
  for (const i in data) notices.push({ message: i + ": " + data[i] });
  Store.commit(PUSH_NOTICES, { notices });
};
*/

// добавляем отправку заголовка, если имеется токен

Axios.interceptors.request.use((config) => {
  const token = Store.getters.token;
  if (token) config.headers["Authorization"] = token;
  return config;
});

// сообщаем об ошибках и обновляем токен при необходимости
Axios.interceptors.response.use(
  (response) => {
    return response;
  }
  //async (error) => {
  //console.log(error);
  //}
);

export default Axios;
