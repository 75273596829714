import getters from "@/store/modules/task/getters";
import mutations from "@/store/modules/task/mutations";
import actions from "@/store/modules/task/actions";
export default {
  state: {
    availableTasks: [],
    latestTasks: [],
    latestResults: [],
    cnt: 0,
    activeTask: { id: 0, level: 0, competence: "", task: "" },
  },
  getters,
  mutations,
  actions,
};
