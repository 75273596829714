export default {
  testGetquestion: (state) => (id) => {
    return state.questions.find((el) => el.id == id);
  },
  testGetquestions: (state) => {
    return state.questions;
  },
  testGetanswers: (state) => {
    return state.answers;
  },
  testGetanswerslist: (state) => {
    return state.answersList;
  },
  testGetactualquestion: (state) => {
    let qNum;
    let qId = 1;
    for (let i in state.questions) {
      qNum = state.questions[i].id;
      if (!state.answersList[qNum]) {
        qId = state.questions[i].id;
        break;
      }
    }
    return qId - 1;
  },
  testGetanswersforquestion: (state) => (qid) => {
    let ansList = [];
    for (let i in state.answers) {
      if (state.answers[i].qid == qid) ansList.push(state.answers[i]);
    }
    return ansList;
  },
  testGetresults: (state) => {
    return state.results;
  },
  testGetchildresults: (state) => {
    return state.childResults;
  },
  testGetanswerspassed: (state) => {
    return state.answersPassed;
  },
};
