export default {
  orgSetpositions(state, list) {
    state.positions = list;
  },
  orgSeteducations(state, list) {
    state.educations = list;
  },
  orgSetskills(state, list) {
    state.skills = list;
  },
  orgSetsuitpositions(state, list) {
    state.suitPositions = list;
  },
  orgSetsuitusers(state, list) {
    state.suitUsers = list;
  },
  orgSeteditid(state, val) {
    state.editId = val;
  },
};
